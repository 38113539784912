<template>
  <v-dialog v-model="createDialog" max-width="800" persistent content-class="align-center" scrollable>
    <v-card>
        <v-card-title class="pb-7">
            Add Contact
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 50vh">
            <v-row class="d-flex align-center">
                <v-col>
                    <div class="body-1">
                        What kind of Contact are you adding?
                    </div>
                </v-col>
                <v-col>
                    <v-radio-group
                        v-model="type"
                        row
                    >
                    <v-radio
                        label="Personal"
                        value="personal"
                    ></v-radio>
                    <v-radio
                        label="Commercial"
                        value="commercial"
                    ></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>

            <v-row v-if="type == 'personal'">
                <v-col cols="12">
                    <h3 class="mb-4">Personal Details</h3>
                    <v-row>
                        <v-col cols="2">
                            <ValidationProvider 
                                name="Prefix" v-slot="{ errors }">
                                <v-text-field 
                                    v-model="form['prefix']"
                                    :error-messages="errors"
                                    outlined
                                    dense 
                                    label="Prefix">
                                </v-text-field>
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="3">
                            <ValidationProvider 
                                name="First Name" v-slot="{ errors }" rules="required">
                                <v-text-field 
                                    v-model="form['first_name']"
                                    :error-messages="errors"
                                    
                                    outlined
                                    dense 
                                    label="First Name">
                                </v-text-field>
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field
                                v-model="form['middle_name']"
                                outlined
                                dense 
                                label="Middle Name">
                            </v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <ValidationProvider 
                                name="Last Name" v-slot="{ errors }" rules="required">
                                <v-text-field 
                                    v-model="form['last_name']"
                                    :error-messages="errors"
                                    outlined
                                    dense 
                                    label="Last Name">
                                </v-text-field>
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field 
                                v-model="form['suffix']"
                                outlined
                                dense 
                                label="Suffix">
                            </v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field 
                                v-model="form['preferred_name']"
                                outlined
                                dense 
                                label="Preferred Name">
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12">
                    <h3 class="mb-4">Contact Details</h3>
                    <v-row>
                        <v-col>
                            <div data-attr="phone-numbers">
                                <div class="d-flex justify-space-between mb-3">
                                    <h4 class="mb-4">Phone Numbers</h4>
                                    <v-btn depressed class="mr-3" color="primary" x-small @click="addPhoneNumber">
                                        <v-icon small>mdi-plus</v-icon> Add Phone number
                                    </v-btn>
                                </div>
                                <v-row v-for="(phone, index) in form.phone_numbers" :key="'phone'+index">
                                    <v-col cols="12" sm="2">
                                        <v-text-field 
                                            outlined
                                            dense 
                                            label="Extension"
                                            v-model="phone.extension">
                                        </v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="3">
                                        <v-text-field 
                                            outlined
                                            dense 
                                            label="Phone Number"
                                            v-model="phone.phone_number">
                                        </v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="2">
                                        <v-select
                                            outlined
                                            dense
                                            :items="phone_number_types"
                                            label="Type"
                                            v-model="phone.type">
                                        </v-select>
                                    </v-col>

                                    <v-col cols="12" sm="3">
                                        <v-switch :true-value="1" :false-value="0" class="ma-0" dense flat v-model="phone.permission_to_contact">
                                            <template v-slot:label>
                                                <span style="font-size: .8em">Permission to contact</span>
                                            </template>
                                        </v-switch>
                                    </v-col>

                                    <v-col cols="12" sm="2" class="d-flex justify-end">
                                        
                                        <v-btn v-if="phone.is_primary" depressed fab small>
                                            <v-icon color="yellow">mdi-star</v-icon>
                                        </v-btn>
                                        <v-btn depressed fab small @click="deleteItem(index, form.phone_numbers)">
                                            <v-icon small>mdi-delete</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>

                            <div data-attr="email-addresses">
                                <div class="d-flex justify-space-between mb-3">
                                    <h4 class="mb-4">Email Addresses</h4>
                                    <v-btn depressed class="mr-3" color="primary" x-small @click="addEmail">
                                        <v-icon small>mdi-plus</v-icon> Add Email Address
                                    </v-btn>
                                </div>

                                <v-row v-for="(email, index) in form.email_addresses" :key="'email'+index">
                                    <v-col cols="12" sm="5">
                                        <v-text-field 
                                            outlined
                                            dense 
                                            label="Email"
                                            v-model="email.email">
                                        </v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="2">
                                        <v-select
                                            outlined
                                            dense
                                            :items="email_types"
                                            label="Type"
                                            v-model="email.type">
                                        </v-select>
                                    </v-col>

                                    <v-col cols="12" sm="3">
                                        <v-switch class="ma-0" dense flat v-model="email.permission_to_contact">
                                            <template v-slot:label>
                                                <span style="font-size: .8em">Permission to contact</span>
                                            </template>
                                        </v-switch>
                                    </v-col>

                                    <v-col cols="12" sm="2" class="d-flex justify-end">
                                        <v-btn v-if="email.is_primary" depressed fab small>
                                            <v-icon color="yellow">mdi-star</v-icon>
                                        </v-btn>
                                        <v-btn depressed fab small @click="deleteItem(index, form.email_addresses)">
                                            <v-icon small>mdi-delete</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>

                            <div data-attr="addresses">
                                <div class="d-flex justify-space-between mb-3">
                                    <h4 class="mb-4">Addresses</h4>
                                    
                                    <v-btn depressed class="mr-3" color="primary" x-small @click="addAddress">
                                        <v-icon small>mdi-plus</v-icon> Add Addresses
                                    </v-btn>
                                </div>

                                <v-row v-for="(address, index) in form.addresses" :key="'address'+index" class="mb-2" style="border-bottom: 1px solid #eee">

                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            outlined
                                            dense 
                                            label="Country"
                                            v-model="address.country">
                                        </v-text-field>
                                    </v-col>


                                    <v-col cols="12" sm="4">
                                        <v-select
                                            @change="selectCountry(index, address.state)"
                                            outlined
                                            dense
                                            :items="states"
                                            label="State"
                                            v-model="address.state">
                                        </v-select>
                                    </v-col>

                                    <v-col cols="12" sm="4">
                                        <v-text-field 
                                            outlined
                                            dense 
                                            label="City"
                                            v-model="address.city">
                                        </v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="3">
                                        <v-text-field 
                                            outlined
                                            dense 
                                            label="Street"
                                            v-model="address.street">
                                        </v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="3">
                                        <v-select
                                            outlined
                                            dense
                                            :items="address_types"
                                            label="Type"
                                            v-model="address.type">
                                        </v-select>
                                    </v-col>

                                    <v-col cols="12" sm="2">
                                        <v-select
                                            outlined
                                            dense
                                            :items="ownership"
                                            label="Own or Rent"
                                            v-model="address.ownership">
                                        </v-select>
                                    </v-col>

                                    <v-col cols="12" sm="2">
                                        <v-text-field 
                                            outlined
                                            dense 
                                            label="Zip Code"
                                            v-model="address.zip_code">
                                        </v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="2" class="d-flex justify-end">
                                        <v-btn v-if="address.is_primary" depressed fab small>
                                            <v-icon color="yellow">mdi-star</v-icon>
                                        </v-btn>
                                        <v-btn depressed fab small @click="deleteItem(index, form.addresses)">
                                            <v-icon small>mdi-delete</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>

                            </div>
                        </v-col>
                    </v-row>

                    <v-row class="mt-5">
                        <v-col cols="12" sm="4">
                            <v-select
                                dense
                                v-model="form['best_time_to_contact']"
                                :items="best_times_to_contact"
                                label="Best Time to Contact"
                                outlined
                            ></v-select>
                        </v-col>

                        <v-col cols="12" sm="4">
                            <v-switch class="ma-0" :true-value="1" :false-value="0" dense flat v-model="form['do_not_contact']">
                                <template v-slot:label>
                                    <span>Do not contact</span>
                                </template>
                            </v-switch>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-row v-else>
                <v-col>commercial</v-col>
            </v-row>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end mt-5">
            <v-btn @click="closeDialog">Close</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    name: 'CreateContactModal',
    props: {
        dialog : {
            required: true
        }
    },
    data() {
        return {
            createDialog: false,
            type: 'personal',
            form: {
                prefix: '',
                first_name: '',
                middle_name: '',
                last_name: '',
                suffix: '',


                best_time_to_contact: '',
                do_not_contact: 0,
                addresses: [],
                birthdate: '',
                client_since: '',
                driver_license_number: '',
                driver_license_state: '',
                email_addresses: [],
                last_contact_date: '',
                lead_source: '',
                marital_status: '',
                next_renewal_date: '',
                occupation: '',
                phone_numbers: [],
                preferred_name: '',
                ssn_last_4: '',
                total_policy_count: '',
                total_premium: '',
                user: null,
                businesses: [],
                relationships: [],
                lead_source_id: null
            },
            address_types: [],
            best_times_to_contact: [],
            business_roles: [],
            email_types: [],
            lead_types: [],
            marital_statuses: [],
            phone_number_types: [],
            relationship_statuses: [],
            relationships: [],
            states: [],
            ownership: [
                { text: 'Own', value: 'own'},
                { text: 'Rent', value: 'rent'}
            ]
        }
    },
    mounted() {
        this.getEnums();
    },
    methods: {
        closeDialog() {
            this.createDialog = false
            this.$emit('close-create-dialog', false)
        },
        deleteItem(index, array) {
                array.splice(index, 1);
        },

        //This will be a vuex store in the future
        getEnums() {
            this.$api.enums.index().then(res => {

                let address_types = []
                let best_times_to_contact = []
                let business_roles = []
                let email_types= []
                let lead_types= []
                let marital_statuses = []
                let phone_number_types = []
                let relationship_statuses = []
                let relationships = []
                let states = []

                for (const [key, value] of Object.entries(res.data.phone_number_types)) {
                    phone_number_types.push(
                        {
                            value: key,
                            text: value
                        }
                    )
                }

                for (const [key, value] of Object.entries(res.data.relationship_statuses)) {
                    relationship_statuses.push(
                        {
                            value: key,
                            text: value
                        }
                    )
                }

                for (const [key, value] of Object.entries(res.data.states)) {
                    states.push(
                        {
                            value: value.short,
                            text: value.name,
                            country: value.country
                        }
                    )
                }

                for (const [key, value] of Object.entries(res.data.relationships)) {
                    relationships.push(
                        {
                            value: key,
                            text: `${value.left} - ${value.right}`
                        }
                    )
                }

                for (const [key, value] of Object.entries(res.data.address_types)) {
                    address_types.push(
                        {
                            value: key,
                            text: value
                        }
                    )
                }

                for (const [key, value] of Object.entries(res.data.best_times_to_contact)) {
                    best_times_to_contact.push(
                        {
                            value: key,
                            text: value
                        }
                    )
                }

                for (const [key, value] of Object.entries(res.data.business_roles)) {
                    business_roles.push(
                        {
                            value: key,
                            text: value
                        }
                    )
                }

                for (const [key, value] of Object.entries(res.data.email_types)) {
                    email_types.push(
                        {
                            value: key,
                            text: value
                        }
                    )
                }

                for (const [key, value] of Object.entries(res.data.lead_types)) {
                    lead_types.push(
                        {
                            value: value.id,
                            text: value.name
                        }
                    )
                }

                for (const [key, value] of Object.entries(res.data.marital_statuses)) {
                    marital_statuses.push(
                        {
                            value: key,
                            text: value
                        }
                    )
                }

                this.address_types = address_types
                this.best_times_to_contact = best_times_to_contact
                this.business_roles = business_roles
                this.email_types= email_types
                this.lead_types= lead_types
                this.marital_statuses = marital_statuses
                this.phone_number_types = phone_number_types
                this.relationship_statuses = relationship_statuses
                this.relationships = relationships
                this.states = states


            })
        },

        addPhoneNumber() {
            let primary = 0

            if(!this.form.phone_numbers.length) {
                primary = 1
            }

            this.form.phone_numbers.push({
                phone_number: null,
                extension: null,
                type: null,
                is_primary: primary,
                permission_to_contact: 0
            })
        },

        addEmail() {
            let primary = 0

            if(!this.form.email_addresses.length) {
                primary = 1
            }

            this.form.email_addresses.push({
                email: null,
                is_primary: primary,
                permission_to_contact: false,
                type: null
            })
        },

        addAddress() {
            let primary = 0

            if(!this.form.addresses.length) {
                primary = 1
            }

            this.form.addresses.push({
                city: null,
                country: null,
                is_primary: primary,
                ownership: null,
                street: '',
                zip_code: null,
                state: null,
                type: null
            })
        },
    },
    watch: {
        dialog(newVal, oldVal) {
            if(newVal) {
                this.createDialog = newVal
                this.addPhoneNumber();
                this.addEmail();
                this.addAddress();
            } else {
                this.form.email_addresses = []
                this.form.phone_numbers = []
                this.form.addresses = []
            }
        }
    }
}
</script>

<style>

</style>