var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mb-5 d-flex justify-end"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.openCreateModal}},[_vm._v("Add Contacts")])],1)],1),_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.contacts,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'contact', params: { id: item.id }}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.email_addresses",fn:function(ref){
var item = ref.item;
return [_c('ul',_vm._l((item.email_addresses),function(email,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(email.email)+" ")])}),0)]}},{key:"item.phone_numbers",fn:function(ref){
var item = ref.item;
return [_c('ul',_vm._l((item.phone_numbers),function(phone,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(phone.number)+" ")])}),0)]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.email)+" ")]}}])})],1)],1),_c('CreateModal',{attrs:{"dialog":_vm.dialog},on:{"close-create-dialog":_vm.closeCreateDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }