<template>
  <div>
        <v-row no-gutters>
          <v-col class="mb-5 d-flex justify-end">
            <v-btn color="primary" @click="openCreateModal">Add Contacts</v-btn>
          </v-col>
        </v-row>
        <v-card flat>
            <v-card-text>

              <v-data-table
                :headers="headers"
                :items="contacts"
                hide-default-footer
              >

              <template v-slot:item.name="{ item }">
                <router-link :to="{ name: 'contact', params: { id: item.id }}">
                  {{ item.name }}
                </router-link>
              </template>

              <template v-slot:item.email_addresses="{ item }">
                <ul>
                  <li v-for="(email, index) in item.email_addresses" :key="index">
                    {{ email.email }}
                  </li>
                </ul>
              </template>

              <template v-slot:item.phone_numbers="{ item }">
                <ul>
                  <li v-for="(phone, index) in item.phone_numbers" :key="index">
                    {{ phone.number }}
                  </li>
                </ul>
              </template>

              <template v-slot:item.email="{ item }">
                  {{ item.email }}
              </template>
            
              </v-data-table>

            </v-card-text>
        </v-card>

        <CreateModal :dialog="dialog" @close-create-dialog="closeCreateDialog"/>
  </div>
</template>

<script>
import CreateModal from '@/components/Contact/CreateContactModal'

export default {
    name: 'ContactList',
    components: {
      CreateModal
    },
    data() {
      return {
          headers: [
            { text: 'Name', value: 'name', sortable : false },
            { text: 'Email', value: 'email_addresses', sortable : false },
            { text: 'Phone Number', value: 'phone_numbers', sortable : false },
            { text: 'Contact Type', value: 'contact_type', sortable : false },
            { text: 'Contact Status', value: 'contact_status', sortable : false },
            { text: 'Owner', value: 'owner', sortable : false },
            { text: 'State', value: 'state', sortable : false },
            { text: 'Recently Contacted', value: 'recently_contacted', sortable : false },
            { text: 'Date Created', value: 'date_created', sortable : false },
            { text: 'X-Date', value: 'x_date', sortable : false },
          ],
          contacts: [],
          dialog: false
      }
    },
    methods: {
      openCreateModal() {
        this.dialog = true
      },
      closeCreateDialog() {
        this.dialog = false
      },
      async getContacts() {
        try {
          let list = await this.$api.contacts.index()
          
          this.contacts = list.data.map(item => {
            return {
              id: item.id,
              ...item.attributes,
              ...item.related
            };
          });
        } catch (error) {
          console.log(error);
        }
      }
    },
    mounted() {
      this.getContacts();
    }
}
</script>

<style>

</style>